import { PageProps } from 'gatsby';
import React from 'react';

import { useCustomMixpanel } from '@services/Mixpanel';
import { ConfirmationPage } from '@src/modules';

const Confirmation = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();

  return <ConfirmationPage {...props} mixpanel={mixpanel} />;
};

export default Confirmation;
